module.exports = {
  fieldManage: {
    headStyle: {
      text: "Headline needed here",
      textColor: "#2E3B48",
      size:"4rem"
    },
    link: "https://www.youtube.com/embed/nvD_vnIDoSs",
  },
  officeManage: {
    headStyle: {
      text: "Headline needed here",
      textColor: "#1946BB",
      size:"4rem"
    },
    link: "https://www.youtube.com/embed/hSG5X1VsFDc",
  },
  
};
