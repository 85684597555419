import React, { useEffect } from "react";
// import Header from '../components/Header';
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import { graphql } from "gatsby";
import "../styles/styles.scss";
import { addEventListner, onClientEntry } from "../utils/Event";
import { ThemeProvider } from "@emotion/react";
import theme from "../components/Theme";
import Use from "../components/Use";
import ThirdCard from "../components/ThirdCard";
import FifthCards from "../components/FifthCards";
import SixthCard from "../components/SixthCard";
import FourthCard from "../components/FourthCard";
import heroData from "../data/hero_data";
import third_card from "../data/third_data";
import sheduleData from "../data/shedule_data";
import HeroImage from "../images/Hero-image1.png";

import icon1 from '../images/3rd-card-image-p2.svg';
import icon2 from '../images/third-card-icon-p2.svg';
import icon3 from '../images/third-card-icon-p2-3.svg';
// import icon from '@mui/material/SvgIcon';
import MediaComp from '../components/MediaComp.jsx'
import Toast from "../components/Toast";
import Footer from "../components/Footer";
import mediaData from "../data/video_data";
// import mobileHero from "../images/hero-mobile-2.jpg"
import Footer2 from '../components/Footer2'

const FeildManagement= () => {
  // useEffect(() => addEventListner());
  // useEffect(() => onClientEntry());

  heroData.heroData2.image = HeroImage;
  // heroData.heroData2.mobileImage=mobileHero;

  third_card.feature_page_2.list.map((ele) => {
    switch (ele.name) {
      case "Easy to Use":
        ele.iconStyle.icon = icon1
        break;
      case "Automated Reports":
        ele.iconStyle.icon = icon2
        break;
      case "Labor Productivity":
        ele.iconStyle.icon = icon3
        break;
      default:
        ele.iconStyle.icon = {};
    }
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Toast/>
        <Navbar />
        <Hero heroData={heroData.heroData2} />
        <ThirdCard thirdCardData={third_card.feature_page_2} />
        <MediaComp data={mediaData.fieldManage}/>
        {/* <FourthCard /> */}
       <FifthCards sheduleData={sheduleData.banner2} />
        <SixthCard /> 
        <Footer/>
        <Footer2/>
      </ThemeProvider>
    </React.Fragment>
  );
};



export default FeildManagement;
