import React from "react";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

const MediaComp = (props) => {
  return (

      <Stack direction="column" spacing={6} mt={6} mb={8} alignItems="center">
        <Typography fontSize="2rem" fontWeight="bold">
          {props.data.headStyle.text}
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}> 
            <CardMedia
              component="iframe"
              sx={{
                borderRadius: "10px",
                aspectRatio: "16 / 9",
              }}
              src={props.data.link}
              title="title"
              controls
            />
          </Grid>
        </Grid>
      </Stack>
  );
};

export default MediaComp;
